/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap');


* {
  /* font-family: "Roboto", sans-serif; */
  font-family: 'Open Sans', sans-serif;
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: 32px;
  font-weight: 600;
}
@media (min-width: 480px) {
  .title {
    font-size: 48px;
  }
}
@media (min-width: 1024px) {
  .title {
    font-size: 64px;
  }
}
.content {
  font-size: 16px;
  font-weight: 300;
}
@media (min-width: 1024px) {
  .content {
    font-size: 18px;
  }
}
.App {
  overflow: hidden;
}
@media (min-width: 1024px) {
  .App {
    overflow: visible;
  }
}
.banner {
  height: 90vh;
}

.admission-icons {
  position: fixed;
  bottom: 2em;
  right: 4em;
  display: flex;
  gap: 1em;
  z-index: 1000;
}
@media (max-width: 468px) {
  .admission-icons {
    justify-content: center; 
    right: auto; 
    left: 50%; 
    transform: translateX(-50%); 
    bottom: 1em; 
    width: 100%;
  }
}
.admission-icons .award {
  color: #000;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  transition: color 0.3s ease;
  background-color: #ffffff;
  padding: 8px;
  border: 2px solid white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.9), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: borderPulse 2s infinite alternate;
}
.admission-icons .award .links {
  display: flex;
  gap: 12px;
  width: 100%;
}
.admission-icons .award a {
  color: #003a6a;
  text-decoration: underline;
  font-weight: normal;
  width: auto;
  margin-bottom: 4px;
}
.admission-icons .award a:hover {
  font-weight: bold;
}

@keyframes borderPulse {
  0% {
    border-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  50% {
    border-color: #0a65fe;
    box-shadow: 0 0 15px rgba(10, 101, 254, 0.6);
  }
  100% {
    border-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}

