.contact-container {
  position: relative;
  // background-image: url(https://jthemes.com/themes/wp/travelers/wp-content/themes/travellers/images/contact.jpg);
  background-attachment: fixed;
  background-color: rgb(0, 58, 106);
  background-size: cover;
  background-position: center;
  padding: 0em 0em;
  .contact-header {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1em;
    margin-bottom: 1em;
    .header-sep {
      width: 13em;
      height: 3px;
      background: rgb(255, 255, 255);
      text-align: center;
      margin: auto;
    }
    .header-top {
      // font-size: 4em;
      font-weight: 300;
      color: #ffffff;
    }
    // @media (max-width: 768px) {
    //   .header-top {
    //     font-size: 2em; 
    //   }
    // }

    /* For screens smaller than 480px (usually phones) */
    // @media (max-width: 480px) {
    //   .header-top {
    //     font-size: 1.5em; 
    //   }
    // }
    .header-top span {
      font-weight: 500;
      color: #ffffff;
    }
    .header-bottom {
      font-weight: 100;
      color: #ffffff;
      // font-size: 1.5em;
    }
  }
  .padding-bottom {
    padding: 0em 0em 4em;
  }
}
.body-left {
  input {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 0;
    height: 4.15em;
    margin-bottom: 0.85em;
    padding: 1em 1.5em;
    font-size: 1.15em;
  }
}
.text-area {
  width: 100%;
  height: 15rem;
  border: 1px solid #fff;
  border-radius: 0;
  margin-bottom: 0.85em;
  font-size: 1.15em;
  position: relative;
  textarea::-webkit-input-placeholder {
    justify-content: flex-start;
  }
  input[type="textarea"]::placeholder,
  textarea::placeholder {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    font-size: 1em;
  }
}
.submit-btn {
  display: flex;
  justify-content: space-around;
  input {
    background-color: rgb(0, 58, 106);
    border-radius: 4px;
    border: none;
  }
  input:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 58, 106);
    transition: 0.5s;
    border: none;
  }
}
