.home {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.home img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  opacity: 1;
}


/* .home-page {
  background-image: url(../Assets/Images/Image1.jpg),
    url(../Assets/Images/Image2.jpg), url(../Assets/Images/Image3.jpg),
    url(../Assets/Images/Image4.jpg);
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    transition: background-image 1s ease-out;
    bottom: 0;
    position: fixed;
} */
.wraper {
  position: relative;
}
.inside {
  position: relative;
  top: 30em;
}
/* Date box(left) */
.date-box {
  height: 6.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0;
}
.date-box .date-left {
  width: 30%;
  background: #3c4251;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.date-box .date-left span:first-child {
  font-size: 16px;
  font-weight: 400;
}
.date-box .date-right {
  width: 70%;
  text-align: center;
}
.subscribe-box {
  background-color: white;
  display: flex;
}
.subscribe-box .subscribe-box-left {
  width: 70%;
  height: 100%;
  padding: 15px;
}
.subscribe-box .subscribe-box-right {
  width: 30%;
  text-align: center;

  background: #3c4251;
}
.subscribe-box .subscribe-box-right span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: white;
  height: 100%;
}
.input-fields {
  border: 1px solid rgb(184, 183, 183);
  height: 40px;
  padding-left: 1em;
}
.input-fields:focus-visible {
  outline: 0;
}
.brochure-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}
.brochure-image {
  max-width: 80%;
  max-height: 80%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
body.disable-scrolling {
  overflow: hidden;
}
