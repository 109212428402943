.multipleGallery {
  background-color: rgb(239, 241, 246);
  padding: 7em 0em;

  .multi-gall-container {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1em;
    margin-bottom: 1em;
    .header-top {
      color: #3c4251;
    }
    .header-sep {
      width: 13em;
      height: 3px;
      background-color: #003a6a;
      text-align: center;
      margin: auto;
    }
    .header-bottom {
      color: #3c4276;
    }
  }
  .image-container {
    .image-block {
      border-radius: 5px;
      box-shadow: 5px 5px 10px #484848, -5px -5px 10px #484848;
      img {
        border-radius: 5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  @media (max-width: 768px) {
    .image-container {
      .gap-s {
        gap: 24px;
      }
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.752);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 888;
    // filter: blur(5px); /* Adjust the blur intensity as needed */
  }
  .close-btn {
    // position: relative;
    position: sticky;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 2em;
    /* right: 1em; */
    top: -0.5em;
  }

  .modal-content {
    background-color: #eff1f6;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1.5em;
    max-width: 70em;
    max-height: 30em;
    overflow-y: auto;
    text-align: center;
  }

  .image-grid {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .square-box {
    position: relative;
    width: 60em;
    height: 40em;
    overflow: hidden;
  }

  .square-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-block {
    position: relative;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .image-block:hover .image-overlay {
    opacity: 1;
  }

  .image-title {
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
  }

  .enlarged-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    backdrop-filter: blur(5px); /* Apply the blur effect to the background */
  }

  .enlarged-image-content {
    background-color: rgb(239, 241, 246);
    padding: 2em;
    border-radius: 6px;
    width: 60em;
    max-height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .close-btn {
      position: absolute;
      top: 0em;
      right: 0.5em;
      cursor: pointer;
      font-size: 3em;
      color: #ffffff;
      border: 1pxsolid red($color: #000000);
    }
  }

  .enlarged-image-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px); /* Apply the blur effect to the background */
    z-index: 99933;
  }
}
