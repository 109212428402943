.contact-container {
  position: relative;
  // background-image: url(https://jthemes.com/themes/wp/travelers/wp-content/themes/travellers/images/contact.jpg);
  background-attachment: fixed;
  background-color: rgb(0, 58, 106);
  background-size: cover;
  background-position: center;
  padding: 0em 0em;
  .contact-header {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1em;
    margin-bottom: 1em;
    .header-sep {
      width: 13em;
      height: 3px;
      background: rgb(255, 255, 255);
      text-align: center;
      margin: auto;
    }
    .header-top {
      // font-size: 4em;
      // font-weight: 300;
      color: #ffffff;
    }
    // @media (max-width: 768px) {
    //   .header-top {
    //     font-size: 2em;
    //   }
    // }

    /* For screens smaller than 480px (usually phones) */
    // @media (max-width: 480px) {
    //   .header-top {
    //     font-size: 1.5em;
    //   }
    // }
    .header-top span {
      font-weight: 500;
      color: #ffffff;
    }
    .header-bottom {
      // font-weight: 100;
      color: #ffffff;
      // font-size: 1.5em;
    }
    .header-nios {
      text-align: left;
    }
  }
  .padding-bottom {
    padding: 0em 0em 4em;
  }
}
.coe-container {
  position: relative;
  background-attachment: fixed;
  background-color: rgb(0, 58, 106);
  background-size: cover;
  background-position: center;
  // padding: 4em 0em 4em;
  .item-container {
    background-color: #ffffff;
    border-radius: 6px;
    padding:  1rem;
    margin-bottom: 16px;
    max-width: 500px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    .item-header-right span:first-child {
      margin-top: 1rem;
    }
  }
  .text-bold{
    font-weight: 600;
  }
}
@media (min-width: 768px) {
  .coe-container{
    .item-container{
      height: 20rem;
    }
  }
}
.read-more {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .more {
    margin-block: 20px;
    color: #ffffff;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      transition: 0.2s;
      color: #b9bece;
    }
  }
}
.image-cards {
  width: 100%;
  display: flex;
  .card {
    width: 254px;
    height: 190px;
    border-radius: 30px;
    background: #e0e0e0;
    box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
