.footer-container {
  padding: 2rem 0;
  background-color: #3c4251;
  // opacity: 60%;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
}
@media (min-width: 520px) {
  .footer-container {
    flex-direction: row;
  }
}
.footer-text {
  font-size: 14px;
}
.icon-container {
  display: flex;
  justify-content: flex-start;
  .icons {
    padding: 4px;
  }
}
.social-icons {
  width: fit-content;
  display: flex;
  gap: 1em;
  z-index: 1000;
  margin: 0 1rem;
}

.social-icons a {
  color: #333; /* Adjust the color as needed */
  font-size: 24px;
  text-decoration: none;
  transition: color 0.3s ease;
  background-color: #ffffff;
  padding: 12px;
  border: 2px black;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.social-icons .insta:hover {
  color: #fe0d52; /* Adjust the hover color as needed */
}
.social-icons .fb:hover {
  color: #0a65fe; /* Adjust the hover color as needed */
}
