.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;            
  overflow: hidden;
}

.modal-container {
  background-color: #003a6a;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 16px; */
  width: 250px;
  height: 20rem;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.modal-container p {
  margin: 0;
}
@media (min-width: 468px) {
  .modal-container {
    width: 400px;
    font-size: 18px;
    font-weight: bold;
  }
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateX(50%) translateY(-50%);
  background-color: #ffffff;
  border: none;
  padding: 4px 8px;
  border-radius: 50px;
  color: #003a6a;
  font-size: 16px;
  cursor: pointer;
}



.modal-container a {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 16px;
}

.modal-container img{
  margin-bottom: 3em;
}