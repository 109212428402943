.navbar-main {
  box-shadow: 0 0 3px 1px rgb(0 0 0 / 15%);
  background-color: #003a6a;
  color: white;
  max-height: 100vh;
  // overflow: hidden;
}
@media (min-width: 1024px) {
  .navbar-item-container {
    gap: 0.5em;
  }
}
// .container-fluid {
//   cursor: no-drop;
// }

// @media screen and (min-width: 1800px) {
//   .container-fluid {
//     overflow-x: auto;
//   }
// }

.container-fluid::-webkit-scrollbar {
  width: 6px; /* Scrollbar width */
  height: 6px; /* Scrollbar height for horizontal scroll */
}

.container-fluid::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scroll thumb */
  border-radius: 50%; /* Round the scrollbar thumb */
}

/* Optionally hide the scrollbar on some browsers */
.container-fluid::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change color on hover */
}

.container-fluid::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

/* Hide scrollbar completely */
.container-fluid::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.container-fluid {
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  scrollbar-color: #888 #f1f1f1; /* Color of the thumb and track */
}

.me-auto {
  padding-left: 2em;
  cursor: pointer;
}
.navbar-toggler {
  border-color: white !important;
  outline: none;

  .navbar-toggler-icon {
    background-image: none !important; /* Remove default Bootstrap icon */
    display: none;
  }


  // &::before {
  //   content: "\2630"; /* Unicode for hamburger icon */
  //   color: white;
  //   font-size: 1.5rem;
  // }
}
.navbar-toggler {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}
.navbar-item-container a {
  color: white;
  font-size: 14px;
  font-weight: normal;
  transition: color 0.3s ease, font-weight 0.3s ease;

  &:hover,
  &:focus,
  &.active {
    color: white !important;
    font-weight: bold !important;
  }
}
.teacherModal {
  font-weight: 400;
  .modal-description {
    background-color: #eaeaf4;
  }
  // .modal-title-wraper {
  //   width: 100vw;
  //   display: flex;
  //   justify-content: center;
  .modal-title {
    background-color: #003a6a;
    color: #ffffff;
  }
  // }
  .modal-body {
    .modal-row {
      margin-bottom: 3em;
      h5 {
        background-color: #003a6a;
        color: #ffffff;
      }
    }
    .modal-row-bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
.dropdown {
  position: relative;
  .dd-items {
    color: black;
    &:hover {
      color: rgb(144, 149, 149);
      background-color: #efefef;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .navbar-item-container {
    font-size: 14px;
  }
}
@media only screen and (max-width: 900px) {
  .navbar-item-container {
    font-size: 14px;
  }
}
.navbar-collapse {
  // overflow: auto;
  max-height: 80vh; // You can adjust this value as needed
  // overflow-y: auto;
  padding-right: 2em;
}
@media (max-width: 1024px) {
  .navbar-collapse {
    padding: 2em 1em;
  }
}
.navbar-container {
  transition: transform 0.3s ease-in-out;

  &.hidden {
    transform: translateY(-100%);
  }

  &.visible {
    transform: translateY(0);
  }
}
.custom-dropdown {
  position: relative;
}
@media (max-width:1023px) {
  .custom-dropdown {
    display: none;
  }
}

@media (min-width:1024px) {
  .mobile-nav {
    display: none;
  }
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0; // Align it to the left edge of the dropdown toggle
  z-index: 1000; // Ensure the dropdown appears above other elements
  background-color: white; // Give it a background color
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-dropdown-menu .dropdown-menu {
  display: block;
}

.dropdown-menu {
  left: -100px !important;
}
