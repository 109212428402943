.info-container {
  padding: 1em 0em;
  background-color: #ffffff;
  .info-left {
    display: table;
  }
  .info-left .main-header-icon {
    // font-size: 4.143em;
    // font-weight: 900;
    color: #3c4251;
  }
  .info-left .sub-header-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    border-radius: 50%;
    margin: 0 3px;
    background-color: rgb(214 215 219);
    height: 3em;
  }
  .sub-header-icon:hover {
    transform: scale(1.2);
    overflow: auto;
    transition: 0.3s;
    background-color: rgb(0, 58, 106);
  }

  .info-left-bottom {
    display: flex;
    margin-top: 2em;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  @media (max-width: 580px) {  
    .margin-sm {
      margin-left: 4px !important;
    }
  }
  .service {
    display: flex;
    gap: .5em;
    align-items: center;
    // width: 100%;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.65em;
    height: 3.65em;
    border-radius: 50%;
    background: rgb(0, 58, 106);
  }
  .ico {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.65em;
    height: 3.65em;
    border-radius: 50%;
    background: rgb(0, 58, 106);
  }
  .icon-text-div {
    display: flex;
    max-width: 220px;
    flex-direction: column;
    justify-content: center;
    a {
      text-decoration: none;
      color: rgb(33, 37, 41);
    }
    a:hover {
      color: rgb(214 215 219);
    }
  }
  .info-bottom {
    margin-top: 3em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
  }
  @media (min-width: 1000px) {
    .info-bottom {
      flex-direction: row;
    }
  }
}

@media (min-width: 468px) {
  .info-container .icon-text-div {
    max-width: 300px;  
  }
}