.gallery-container {
  background-color:  rgb(0, 58, 106);
  padding: 0em;
  .gallery-header {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1em;
    margin-bottom: 7em;
    .header-sep {
      width: 13em;
      height: 3px;
      background: rgb(239, 241, 246);
      text-align: center;
      margin: auto;
    }
    .header-top {
      // font-size: 4em;
      font-weight: 300;
      color: #879489;
    }
    // @media (max-width: 768px) {
    //   .header-top {
    //     font-size: 2em;
    //   }
    // }

    /* For screens smaller than 480px (usually phones) */
    // @media (max-width: 480px) {
    //   .header-top {
    //     font-size: 1.5em;
    //   }
    // }
    .header-top span {
      font-weight: 500;
      color: #ffffff;
    }
    .header-bottom {
      font-weight: 100;
      color: #ffffff;
      // font-size: 1.5em;
    }
  }

  // .image-slide {
  //   display: flex;
  //   justify-content: center;
  //   img {
  //     width: 356px;
  //     height: 356px;
  //   }
  //   .slide-buttons {
  //     display: flex;
  //     justify-content: center;
  //   }
  // }

  .slide-container {
    display: flex;
    overflow-x: scroll;
    div::--webkit-scrollbar {
      display: none;
    }
    img::--webkit-scrollbar {
      display: none;
    }
  }
  .slide-col {
    cursor: pointer;
    width: 361px;
    height: 361px;
    background-color: #eff1f6;
    //   padding: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 356px;
      height: 356px;
    }
    img:hover {
      transform: scale(1.1);
      overflow: auto;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
  }
  // modal styles
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 20px;
    max-width: 800px;
    max-height: 80%;
    overflow-y: auto;
    text-align: center;
  }

  .backdrop.hidden {
    display: none;
  }

  .backdrop img {
    width: 100%;
    max-width: 100%;
    max-height: 80%;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #484848, -5px -5px 10px #484848;
  }

  .backdrop::after {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: -1;
    backdrop-filter: blur(10px);
  }
  .image-main {
    display: flex;
    width: 100%;
    .image-container {
      position: relative;
      width: 20%;
      height: 20em;
      &:hover {
        img {
          background: black;
          filter: brightness(50%);
          transform: scale(1.1);
        }
        svg {
          display: block;
          background: #003a6a;
          padding: 10px;
          font-size: 60px;
          position: absolute;
          top: 50%;
          left: 50%;
          color: white;
          transform: translate(-50%, -50%);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.5s ease all;
      }
      svg {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: 0.5s ease-in-out;
      }
    }
  }
}


.media-container {
  background-color: rgb(0, 58, 106);
  padding: 1em;
  text-align: center;
  @media (min-width: 480px) {
    .media-container {
      padding: 2em;
    }
  }


  .media-header {
    margin-bottom: 2em;
    .title {
      // font-size: 2rem;
      font-weight: bold;
      color: #ffffff;
    }
    .header-sep {
      width: 10em;
      height: 3px;
      background: rgb(239, 241, 246);
      margin: auto;
    }
  }
  .media-header {
    .header-bottom {
      .subheading {
        margin-top: 20px !important;
      }
    }
  }

  .scroll-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  .scroll-icon {
    font-size: 40px;
    color: white;
    cursor: pointer;
    z-index: 10;
    padding: 10px;
  }

  .left {
    position: absolute;
    left: -50px;
  }

  .right {
    position: absolute;
    right: -50px;
  }

  .image-main {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
    gap: 10px;

    &::-webkit-scrollbar {
      // display: none; /* Hide scrollbar */
      height: 4px;
      width: 6px;
      background-color: black;
      border-radius: 50%;
    }
    &::-webkit-scrollbar-thumb {
      background-color: white;
      border-radius: 20px;
    }
  }

  .image-container {
    position: relative;
    width: 300px; /* Adjust width */
    height: 300px; /* Adjust height */
    flex: 0 0 auto;
    cursor: pointer;

    @media (min-width: 480px) {
      .image-container {
        width: 500px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
    }
    &:hover img {
      background-color: black;
      opacity: 40%;
    }

    svg {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 60px;
      cursor: pointer;
    }

    &:hover svg {
      display: block;
    }
  }
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .modal-content {
    max-width: 500px;
    max-height: 500px;
    overflow: hidden;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}