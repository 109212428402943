.itinarary-container {
  background-color: #003a6a;
  padding: 3em 0em;
}

.itinarary-header {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1em;
  margin-bottom: 1em;
}
// .itinarary-header .header-bottom::before {
//   content: " ";
//   position: absolute;
//   width: 7em;
//   height: 3px;
//   background: red;
//   text-align: center;
// }
.itinarary-header .header-sep {
  width: 13em;
  height: 3px;
  background: white;
  text-align: center;
  margin: auto;
}
.itinarary-body-left {
  padding: 0;
}
.header-top {
  // font-size: 4em;
  font-weight: 300;
  color: white;
}
// @media (max-width: 768px) {
//   .header-top {
//     font-size: 2em;
//   }
// }

/* For screens smaller than 480px (usually phones) */
// @media (max-width: 480px) {
//   .header-top {
//     font-size: 1.5em;
//   }
// }
.header-top span {
  font-weight: 500;
}
.header-bottom {
  font-weight: 100;
  color: white;
  // font-size: 1.5em;
}
// .card {
//   .card-container {
//     margin: 0;
//     // --bs-gutter-x: 0;
//   }
//   padding: 0;
//   .card-body {
//     padding: 2rem;
//     .card-text {
//       margin-bottom: 2rem;
//       margin-top: 2rem;
//     }
//   }
// }
.itinarary-main {
  width: 100%;
  position: relative;
  margin-bottom: 7em;
  .tube {
    position: absolute;
    width: 4.65em;
    height: 104%;
    background: #878a94;
    top: 18px;
    z-index: 1;
    left: 50%;
    margin-left: -34px;
    .tube-top {
      display: flex;
      justify-content: center;
      position: absolute;
      align-items: center;
      width: 4.65em;
      height: 4.65em;
      top: -34px;
      border-radius: 50%;
      background: #878a94;
      border: none;
      text-transform: uppercase;
      font-weight: 600;
      color: white;
    }
    .tube-end {
      display: flex;
      justify-content: center;
      position: absolute;
      align-items: center;
      width: 4.65em;
      height: 4.65em;
      bottom: -34px;
      border-radius: 50%;
      background: #878a94;
      border: none;
      text-transform: uppercase;
      font-weight: 600;
      color: white;
    }
  }
  .car-scroll {
    text-align: center;
    position: sticky;
    z-index: 3;
    top: 43%;
    .car {
      margin-left: 5px;
      img {
        width: 8rem;
        height: 11rem;
      }
    }
  }
  .content-div {
    width: 100%;
    display: flex;
    position: relative;
    .line {
      position: absolute;
      top: -2.6em;
      left: 50.1%;
      bottom: 0em;
      width: 1px;
      border-left: 1px dashed #fff;
      border-width: 3px;
      border-spacing: 0.85em;
      z-index: 2;
      &:last-child {
        bottom: 0.7em;
      }
    }
    &.reverse-row {
      display: flex;
      flex-direction: row-reverse;
      .itinarary-right {
        position: relative;
        width: 50%;
        .day-reverse {
          position: absolute;
          top: 44%;
          right: -51.5px;
          background: grey;
          width: 6em;
          height: 6em;
          z-index: 3;
          border-radius: 50%;
          border: 10px solid white;
          box-shadow: 0 0 5px 1px rgb(0 0 0 / 30%);
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-weight: 600;
            color: white;
          }
        }
      }
    }
    .itinarary-left {
      width: 50%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .itinarary-right {
      position: relative;
      width: 50%;
      .day-container {
        position: absolute;
        top: 44%;
        left: -44.5px;
        background: grey;
        width: 6em;
        height: 6em;
        z-index: 3;
        border-radius: 50%;
        border: 10px solid white;
        box-shadow: 0 0 5px 1px rgb(0 0 0 / 30%);
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-weight: 600;
          color: white;
        }
      }
      .card {
        border-radius: 0;
        height: 100%;
        @media (min-width: 1024px) {
          padding-inline: 3em;
        }
        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .separator-color {
            border-color: #b4b4b4;
          }
          .card-title {
            // font-size: 2rem;
            text-align: center;
            color: #575757;
            z-index: 4;
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
            a {
              color: #575757;
              text-decoration: none;
            }
          }
          .card-subtitle {
            // font-size: 0.95rem;
            font-weight: 400;
            color: #a0a0a0;
          }
          .card-text {
            margin: 1.7em 0;
            font-size: 12px;
            font-weight: 300;
          }
        }
        .itinarary-content {
          .itinarary-content-text {
            display: grid;
            span {
              &:first-child {
                // font-size: 16px;
                color: #3c4251;
                font-weight: 600;
                &::after {
                  content: ":";
                }
              }
              &:last-child {
                // font-size: 14px;
                font-weight: 400;
                margin-top: 0.35em;
              }
            }
          }
        }
      }
    }
  }
}
