.doc-container {
  background-color: #eff1f6;
  padding: 1em 0em;
}

.doc-container::before {
  display: table;
}
.doc-left {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  /* align-content: center; */
  flex-direction: column;
}
.doc-left .tabs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2em;
}
.doc-left .main-header-text {
  /* font-size: 2.5rem; */
  font-weight: 900;
  color: #3c4251;
  font-style: italic;
}
.doc-left .sub-header-text {
  /* font-size: 2.143em; */
  /* font-weight: 900; */
  line-height: 26px;
}
.doc-left-bottom {
  margin-top: 1em;
  width: 90%;
}
.doc-left-img {
  width: 29rem;
  height: 34rem;
}
.doc-left-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
/* .doc-left-bottom::before {
  content: " ";
  position: absolute;
  width: 7em;
  height: 3px;
  background: rgb(0, 58, 106);
} */
 .main-header{
  width: 100%;
  text-align: center;
  font-family: pl;
  color: #003a6a;
 }
.doc-right-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.doc-right-content {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}
@media (min-width: 770px) {
  .doc-right-content {
    flex-direction: row;
  }
}

.doc-right-header {
  width: 100%;
  /* font-size: 4rem; */
  display: flex;
  margin-bottom: 2rem;
  font-weight: 600;
  /* text-decoration: underline; */
  color: #003a6a;
  /* justify-content: space-around; */
}
/* @media (max-width: 768px) {
  .doc-right-header,
  .doc-left .main-header-text {
    font-size: 2em;
  }
} */
/* .doc-right {
  font-size: 1rem;
  font-weight: 500;
} */
/* .service {
  display: flex;
  gap: 1.5em;
  align-items: center;
} */
/* .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.65em;
  height: 3.65em;
  border-radius: 50%;
  background: rgb(0, 58, 106);
} */
.icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.icon-text-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* .icon-text-div span {
  font-weight: 400;
  font-size: 16px;
} */
/* .icon-text-div span:last-child {
  font-weight: 700;
  font-size: 1.7rem;
} */
.doc-bottom {
  margin-top: 3em;
}
.career {
  /* position: relative; */
  /* margin-bottom: 3em; */
  top: -5em;
  left: -5em;
  padding: 0.4em 1em;
  /* max-width: max-content; */
  /* min-width: min-content; */
  width: auto;
  font-weight: 500;
  cursor: pointer;
  background-color: #003a6a;
  /* background-color: #003a6a; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 4px;
  transition: 0.3s;
  &:hover {
    color: black;
    /* background: rgb(172, 172, 155); */
  }
}
a {
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  /* font-size: 19px; */
  color: white;
  /* color: #054e89; */
  transition: 0.2s;
  /* &:hover {
    color: rgb(96, 96, 96);
  } */
}
.glitch {
  position: relative;
  /* font-size: 25px; */
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  letter-spacing: 5px;
  z-index: 1;
  animation: shift 1s ease-in-out infinite alternate;
}

.glitch:before,
.glitch:after {
  display: block;
  content: attr(data-glitch);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.glitch:before {
  animation: glitch 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  color: #8b00ff;
  z-index: -1;
}

.glitch:after {
  animation: glitch 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both
    infinite;
  color: #00e571;
  z-index: -2;
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-3px, 3px);
  }

  40% {
    transform: translate(-3px, -3px);
  }

  60% {
    transform: translate(3px, 3px);
  }

  80% {
    transform: translate(3px, -3px);
  }

  to {
    transform: translate(0);
  }
}

@keyframes shift {
  0%,
  40%,
  44%,
  58%,
  61%,
  65%,
  69%,
  73%,
  100% {
    transform: skewX(0deg);
  }

  41% {
    transform: skewX(10deg);
  }

  42% {
    transform: skewX(-10deg);
  }

  59% {
    transform: skewX(40deg) skewY(10deg);
  }

  60% {
    transform: skewX(-40deg) skewY(-10deg);
  }

  63% {
    transform: skewX(10deg) skewY(-5deg);
  }

  70% {
    transform: skewX(-50deg) skewY(-20deg);
  }

  71% {
    transform: skewX(10deg) skewY(-10deg);
  }
}
